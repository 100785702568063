<template>
  <div v-if="!cookiesAccepted" class="fixed left-4 w-[calc(100%-2rem)] sm:w-96 sm:left-auto sm:right-4 bottom-5 bg-white border-grey-500 rounded-m border p-4">
    <div class="cookie-banner__content">
      <i18n-t keypath="cookies.accept.message" tag="p" class="mb-4 text-sm">
        <template v-slot:link>
          <a :href="localePath('/privacy-policy')" target="_blank" class="underline text-purple-500">
            {{ $t('cookies.accept.link') }}
          </a>
        </template>
      </i18n-t>

      <div class="flex justify-end">
        <button class="font-bold bg-brand-500 text-white rounded-s whitespace-nowrap text-sm py-2 px-8" @click="accepted">{{ $t('cookies.accept.button') }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
const { $fb } = useNuxtApp();
const cookiesAccepted = useCookie('cookie-accept', {
  default: () => {
    return false;
  },
  maxAge: 365 * 24 * 60 * 60,
})
if (cookiesAccepted.value) {
  if ($fb) $fb.enable();
}

const accepted = () => {
  cookiesAccepted.value = true;
  if ($fb) {
    $fb.enable();
    $fb.track('cookies-accepted');
  }
}
</script>

<style scoped>

</style>